import React from 'react';
import { Box } from '@mui/material';

export default function SvgLoader() {
  return (
    <Box sx={{ width: '200px' }}>
      <svg
        version="1.1"
        id="L4"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
      >
        <g
          id="Grupo_2329"
          data-name="Grupo 2329"
          transform="translate(-90 -35.136)"
        >
          <path
            id="Trazado_3959"
            data-name="Trazado 3959"
            d="M709.6,664.825l-10.615,0,15.55-27.557h10.606Z"
            transform="translate(-578.362 -591.126)"
            fill="#6b62e3"
          >
            <animate
              attributeName="opacity"
              dur="3s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </path>
          <g id="_2" data-name=" 2" transform="translate(136.172 46.139)">
            <animate
              attributeName="opacity"
              dur="3s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
            <g id="Grupo_2302" data-name="Grupo 2302" transform="translate(0 0)">
              <path
                id="Trazado_3902"
                data-name="Trazado 3902"
                d="M759.531,664.822l9.262-12.622a2.038,2.038,0,0,0,0-2.31l-9.262-12.626h10.606l9.262,12.626a2.037,2.037,0,0,1,0,2.31l-9.262,12.624Z"
                transform="translate(-759.531 -637.265)"
                fill="#6b62e3"
              />
            </g>
          </g>
          <path
            id="Trazado_3901"
            data-name="Trazado 3901"
            d="M681.744,637.255l-9.262,12.625a2.037,2.037,0,0,0,0,2.31l9.262,12.625h-10.6l-9.263-12.622a2.038,2.038,0,0,1,0-2.31l9.263-12.625Z"
            transform="translate(-550.517 -591.119)"
            fill="#6b62e3"
          >
            <animate
              attributeName="opacity"
              dur="3s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </path>
        </g>
      </svg>
    </Box>
  )
};
